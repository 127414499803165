import { memo, useMemo, useRef, useState } from 'react';
import { convertLineCoordsToRectConfig, detectPanelColor, resizePoints } from '../util';
import { Line, Rect } from 'react-konva';
import { PANEL_OFF_COLOR, PANEL_STROKE_COLOR, SEMI_TRANPARENT_BLACK_PANEL, STROKE_COLOR_FOR_USER_MAPPED_PANEL } from '../../../constants';
import { useDispatch, useSelector } from 'react-redux';
import { updateEnergyStateFromPanelClicks } from 'store/slices/EnergySlice/energySlice';
import { EnergyState } from 'store/slices/EnergySlice/types';
import { RootState } from 'store';
import { setIntermediateEnergyState, toggleOffsetState } from 'store/slices/OffsetModelSlice/offsetmodelSlice';
import { useRefs } from 'contexts/RefContext';
import { calculateOffsetPercentage, getDeratedValue } from '../../../util';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { setDeletedPanels } from 'store/slices/PanelSlice';
import { KonvaEventObject } from 'konva/lib/Node';
import Konva from 'konva';
import { Polygon, Response, testPolygonPolygon, Vector } from 'sat';
import { getSnappedPosition } from '../PanelUtil';

type Props = {
	panelData: RasterSolarPanel & { userMapped: boolean , azimuth: number};
	panelId: string,
};

function SolarPanel(props: Props) {

	const { panelData: panel, panelId } = props;
	const dispatch = useDispatch();
	const { energy, billEnergy, selectedPanels, deratedBy } = useSelector((state: RootState) => state.energyData.data as unknown as EnergyState);
	const { userHasGivenPermToExceedOffset } = useSelector((state: RootState) => state.offsetModal.data);

	const { editModeEnabled, roofIndexes, userHasClickedSave, saveWithPanelColor, moveStage } 
	= useSelector((state: RootState) => state.toolNewPostions.data);

	const { enablePanelMove, deleteIndividualPanelMode } = useSelector((state: RootState) => state.panelSlice.data);

	const { org } = useSelector((state: RootState) => state.roofData.data);
	const { sliderRef, konvaRef } = useRefs();
	const scale = useKonvaScale();
	const [panelState, setPanelState] = useState<{ currentParent: any; previousPosition: { x: number; y: number } | null }>({
		currentParent: panel.segmentId,
		previousPosition: null
	});

	const panelIsInEditMode = editModeEnabled || roofIndexes.includes(panel.segmentId);
	const canSelectPanel = !panelIsInEditMode && !moveStage;
	const [color, setColor] = useState(() => {
		if (selectedPanels.includes(panel.id)) return detectPanelColor(panel.yearlyEnergyDcKwh);
		return PANEL_OFF_COLOR;
	});
	
	{
		let panelColor;
		if (panelIsInEditMode)
			panelColor = SEMI_TRANPARENT_BLACK_PANEL;
		else {
			if(userHasClickedSave && !saveWithPanelColor)
				panelColor = selectedPanels.includes(panel.id) ? SEMI_TRANPARENT_BLACK_PANEL : PANEL_OFF_COLOR;
			else
				panelColor = selectedPanels.includes(panel.id) ? detectPanelColor(panel.yearlyEnergyDcKwh) : PANEL_OFF_COLOR;
		}
			
		if (color !== panelColor) {
			setColor(panelColor);
		}
	}

	function updateOffsetModal() {
		let offsetUpdated = false;
		if (color === PANEL_OFF_COLOR) {
			const intermediiateOffset = calculateOffsetPercentage(getDeratedValue((energy + panel.yearlyEnergyDcKwh), deratedBy), billEnergy);
			dispatch(setIntermediateEnergyState({ value: { source: 'panel', data: { panelInfo: { panel, panelId } } } }));
			if (!userHasGivenPermToExceedOffset.offset120 && intermediiateOffset >= 120 && org.setting?.enableOffsetWarning) {
				dispatch(toggleOffsetState({ value: true }));
				offsetUpdated = true;
			}
			if (!userHasGivenPermToExceedOffset.offset150 && intermediiateOffset >= 150 && org.setting?.enableOffsetWarning) {
				dispatch(toggleOffsetState({ value: true }));
				offsetUpdated = true;
			}
		}
		return offsetUpdated;
	}

	function updateSLider(dir: string) {
		if (!sliderRef?.current) return;
		const step = +sliderRef.current.step;
		const sliderVal = +sliderRef.current.value;
		let rangeVl;
		if (dir === 'up') {
			sliderRef.current.value = (sliderVal + step).toString();
			rangeVl = ((sliderVal + step) / +sliderRef.current.max) * 100;
		}
		else {
			sliderRef.current.value = (sliderVal - step).toString();
			rangeVl = ((sliderVal - step) / +sliderRef.current.max) * 100;
		}
		sliderRef.current.style.background = `linear-gradient(to right, var(--primary) 0%, var(--primary) ${rangeVl}%, #a1a1a1 ${rangeVl}%, #a1a1a1 100%)`;
	}

	function updateColorAndMetada() {
		const energyBoost = color === PANEL_OFF_COLOR ? panel.yearlyEnergyDcKwh : -panel.yearlyEnergyDcKwh;
		const irradianceBoost = color === PANEL_OFF_COLOR ? panel.irradiance : -panel.irradiance;
		dispatch(updateEnergyStateFromPanelClicks({ energyBoost, panelId, irradianceBoost }));
		setColor((prevState) => (prevState === PANEL_OFF_COLOR ? detectPanelColor(panel.yearlyEnergyDcKwh) : PANEL_OFF_COLOR));
	}

	function handleClick() {
		if(deleteIndividualPanelMode){
			dispatch(setDeletedPanels({data: {panelId, roofSegmentId: panel.segmentId }}));
			return;
		}
		if (panelIsInEditMode) return;
		if (!canSelectPanel) return;
		if (!updateOffsetModal()) {
			updateColorAndMetada();
			updateSLider((color === PANEL_OFF_COLOR) ? 'up' : 'down');
		}
	}

	const panelPoints = useMemo(() => {
		return resizePoints(panel.exteriorCoords.flat(), scale);
	},[scale, panel.exteriorCoords]);

	const strokeColor = useMemo(() => {
		return (panel?.userMapped && editModeEnabled) ? STROKE_COLOR_FOR_USER_MAPPED_PANEL : PANEL_STROKE_COLOR;
	}, [editModeEnabled, panel?.userMapped]);
	
	// Helper to convert degrees to radians
	function degToRad(deg: number) {
		return (deg * Math.PI) / 180;
	}

	function handleDragMove(e: KonvaEventObject<DragEvent>) {
		const shape = e.target; // The shape being dragged (a Line)
		const currentPos = shape.position();
		
		// Get all sibling shapes, excluding the current shape
		const siblings = shape.getParent().getChildren().filter((eachChild: any) => eachChild.id() !== shape.id());
		
		// Perform snapping/collision check using SAT.js
		const snappedPosition = getSnappedPosition(currentPos, shape, siblings);
		
		// Set the new position for snapping/collision resolution (if needed)
		if (snappedPosition) {
			shape.position(snappedPosition);
		}
	}
	return (
		<Line
			id={panel.id}
			stroke={strokeColor}
			closed={true}
			strokeWidth={1.4}
			points={panelPoints}
			fill={color}
			onClick={handleClick}
			onTap={handleClick}
			onDragMove={handleDragMove}
			draggable={enablePanelMove}
		/>
	);
}

export default memo(SolarPanel);

