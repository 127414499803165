import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { setDeleteIndividualPanelsMode } from 'store/slices/PanelSlice';

export default function DeletePanelBtn() {
	const dispatch = useDispatch();
	const { activePanelMode, deleteIndividualPanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const shouldDisable = activePanelMode && !deleteIndividualPanelMode;

	function handleClick() {
		dispatch(setDeleteIndividualPanelsMode({enabled: !deleteIndividualPanelMode}));
	}

	return (
		<Button style={{
			background: `${deleteIndividualPanelMode ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
			border: '0px',
			borderRight:'0px',
			borderLeft:'0px',
			color: '#28373E',
			fontSize: '15px',
			padding: '0px',
			height: '36px',
			width: `${!deleteIndividualPanelMode ? '40px' : '48px'}`,
			borderRadius:'0px'
		}} 
		disabled={shouldDisable}
		onClick={handleClick} title="Delete Panel">
			<svg width="17" viewBox="0 0 18 21" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7 17H1V1H13V9.5" stroke="#4A525A" strokeWidth="1.6" strokeLinejoin="round"/>
				<path d="M14.8278 17.8284L9.17097 12.1716M14.8278 12.1716L9.17097 17.8284" stroke="#4A525A" strokeWidth="1.6" strokeLinejoin="round"/>
			</svg>

		</Button>
	);
}