import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';

type InfoModal = {
	open: boolean,
	infoMessage: string,
};

const initialState: { data: InfoModal } = {
	data: {
		open: false,
		infoMessage: ''
	}
};

export const InfoModalSlice = createSlice({
	name: 'InfoModalProvider',
	initialState,
	reducers: {
		toggleInfoModalState(
			state,
			action: PayloadAction<{ open: boolean, infoMessage: string }>
		) {
			state.data.open = action.payload.open;
			state.data.infoMessage = action.payload.infoMessage;
		}
	}
});

export const { toggleInfoModalState } = InfoModalSlice.actions;

export default InfoModalSlice.reducer;