import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { NewlyAddedPanel, CurrentMovablePanel, DeletedPanel, PanelSlice } from './types';
import { initializeState } from './util';

const initialState: { data: PanelSlice } = {
	data: initializeState()
};

function resetUnfinalizedState(state: PanelSlice) {
	state.enableAddPanel = false;
	state.newlyAddedPanels = [];
	state.enablePanelMove = false; 
	state.currentMovablePanels = [];
	state.deleteIndividualPanelMode = false;
	state.deletedPanels = [];
}

export const PanelStateSlice = createSlice({
	name: 'PanelSlice',
	initialState,
	reducers: {
		setAddPanel: (state, action: PayloadAction<{ shouldEnable: boolean }>) => {
			state.data.enableAddPanel = action.payload.shouldEnable;
			if (!action.payload.shouldEnable) {
				state.data.newlyAddedPanels = [];
				state.data.activePanelMode = false;
			} else {
				state.data.activePanelMode = true;
			}
		},
		setNewlyAddedPanel: (state, action: PayloadAction<{ addedPanel: NewlyAddedPanel }>) => {
			state.data.newlyAddedPanels = [...state.data.newlyAddedPanels, action.payload.addedPanel];
		},
		setPanelMove: (state, action: PayloadAction<{ shouldEnablePanelMove: boolean }>) => {
			state.data.enablePanelMove = action.payload.shouldEnablePanelMove;
			if (!action.payload.shouldEnablePanelMove) {
				state.data.currentMovablePanels = [];
				state.data.activePanelMode = false;
			} else {
				state.data.activePanelMode = true;
			}
		},
		setCurrentMovablePanels: (state, action: PayloadAction<{ currentMovablePanel: CurrentMovablePanel }>) => {
			const newPanels = state.data.currentMovablePanels.filter(eachPanel => eachPanel.panelId !== action.payload.currentMovablePanel.panelId);
			state.data.currentMovablePanels = [...newPanels, action.payload.currentMovablePanel];
		},
		setDeletedPanels: (state, action: PayloadAction<{ data: DeletedPanel | null }>) => {
			state.data.deletedPanels = !action.payload.data ? [] : [...state.data.deletedPanels, action.payload.data];
		},
		setDeleteIndividualPanelsMode: (state, action: PayloadAction<{ enabled: boolean }>) => {
			state.data.deleteIndividualPanelMode = action.payload.enabled;
			if (!action.payload.enabled) {
				state.data.deletedPanels = [];
				state.data.activePanelMode = false;
			} else {
				state.data.activePanelMode = true;
			}
		},

	}
});

export const {
	setAddPanel, setNewlyAddedPanel,
	setPanelMove, setCurrentMovablePanels, setDeletedPanels, setDeleteIndividualPanelsMode
} = PanelStateSlice.actions;

export default PanelStateSlice.reducer;