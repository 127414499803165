import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import { useSelector } from 'react-redux';
import { RootState } from 'store';
import { InfoModalPopup, ModalcontentInfo } from './style';
import Loader from 'components/Loader';

export default function InfoModal() {
	const { open, infoMessage } = useSelector((state: RootState) => state.infoModal.data);
	
	return (
		<Popup
			contentStyle={{ width: '560px', borderRadius: '4px' }}
			open={open}
			closeOnDocumentClick={false}
		>
			<InfoModalPopup>
				<ModalcontentInfo>
					<Loader loaderText={infoMessage}/>
				</ModalcontentInfo>
			</InfoModalPopup>
		</Popup>
	);
}