import { memo, useCallback, useMemo, useRef } from 'react';
import SolarPanel from '../Panel/SolarPanel';

import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { Group, Transformer } from 'react-konva';
import Konva from 'konva';
import { setRoofIndexes, setSelectedRoofIndex } from 'store/slices/ToolSlice';
import { useRefs } from 'contexts/RefContext';
import {
	AZIMUTH_CONSTRAINT, SEMI_TRANPARENT_BLACK_PANEL, FULL_TRANPARENT_BLACK_PANEL
} from '../../../constants';
import { disableDraggingInKonvaGroups, resetTransformations } from '../util';
import { KonvaEventObject } from 'konva/lib/Node';
import { setDropdownState } from 'store/slices/EditDeleteOptionsModalSlice';
import Facets from './Facets';
import HullCoords from './HullCoords';
import PanelsWithHull from './PanelsWithHull';

function PanelsGroup(): JSX.Element {

	const { rasterSolarPanels: solarPanels, panel, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { 
		roofIndexes, editModeEnabled, 
		selectedRoofIndex, deletedRoofIndexes, 
		drawModeEnabled, moveStage
	} = useSelector((state: RootState) => state.toolNewPostions.data);
	const { enableAddPanel, enablePanelMove, deleteIndividualPanelMode } = useSelector((state: RootState) => state.panelSlice.data);
	const dispatch = useDispatch<AppDispatch>();
	const canPerformEditMoveActions = editModeEnabled && !moveStage && !drawModeEnabled;

	const groupRefs = useRef<Konva.Group[] | null>([]);
	const trRef = useRef<Konva.Transformer | null>(null);
	const transformTimerRef = useRef<{
		timeElapsed: number,
		timeOut?: NodeJS.Timeout
	}>({
		timeElapsed: 0
	});
	const longTouchTimerRef = useRef<NodeJS.Timeout | undefined>(undefined);

	const { konvaRef } = useRefs();
	const isIndividualPanelOperation= enableAddPanel || deleteIndividualPanelMode|| enablePanelMove;
	const solarPanelsGroupedByRoofIndex = useMemo(() => {
		groupRefs?.current && disableDraggingInKonvaGroups(groupRefs.current);
		groupRefs?.current && resetTransformations(groupRefs.current);
		groupRefs.current = [];
		trRef.current?.setNodes([]);
		
		const allRoofSegsObj: { [key: string]: RasterRoofSegment } = {};
		allRoofSegs.forEach(roofSeg => {
			allRoofSegsObj[roofSeg.id] = roofSeg;
		});
		const panelsToSelect = solarPanels;

		const obj: { [key: string]: Array<RasterSolarPanel & { userMapped:boolean }>} = {};
		for (let i = 0; i < panelsToSelect.length; i++) {
			const roofIndex = panelsToSelect[i].segmentId;
			const panel= {
				...panelsToSelect[i],
				userMapped: !!allRoofSegsObj[roofIndex]?.userMapped
			};
			if (!obj[roofIndex]) obj[roofIndex] = [panel];
			else obj[roofIndex].push(panel);
		}
		return obj;
	}, [solarPanels, editModeEnabled, allRoofSegs]);

	if (drawModeEnabled) {
		unselectFacet();
	}

	function addRoofIndex() {
		const roofIndex = trRef.current?.getNode()?.attrs.name.split('>')[2];

		if (!roofIndexes.includes(roofIndex))
			dispatch(setRoofIndexes({ roofIndexes: [...roofIndexes, roofIndex] }));
	}

	function makeFacetSelectable(name: string) {
		const roofIndex = name.split('>')[2];
		console.log('roofIndexselect', roofIndex, name);
		if (!roofIndex) return;
		if (roofIndex === selectedRoofIndex) return;
		dispatch(setSelectedRoofIndex({ selectedRoofIndex: roofIndex }));
	}

	const constraintHandleRotation = useCallback((() => {
		let previousAngle = trRef.current?.getAbsoluteRotation();
		let groupName = trRef.current?.getNode()?.attrs?.name;
		return function () {
			const currentGroupName = trRef.current?.getNode()?.attrs?.name;

			if (!previousAngle || currentGroupName !== groupName) {
				previousAngle = trRef.current?.getAbsoluteRotation();
				groupName = currentGroupName;
				return;
			}
			const newAngle = trRef.current?.getAbsoluteRotation();
			if (!newAngle) return;

			const userIsRotatingFromLeftToRight = newAngle < -AZIMUTH_CONSTRAINT && newAngle > previousAngle;
			if (userIsRotatingFromLeftToRight) {
				previousAngle = newAngle;
				return;
			}

			const userIsRotatingFromRightToLeft = newAngle > AZIMUTH_CONSTRAINT && newAngle < previousAngle;
			if (userIsRotatingFromRightToLeft) {
				previousAngle = newAngle;
				return;
			}

			if (newAngle > AZIMUTH_CONSTRAINT || newAngle < -AZIMUTH_CONSTRAINT) {
				trRef.current?.stopTransform();
				previousAngle = newAngle;
				return;
			}
		};
	})(), [editModeEnabled]);

	function unselectFacet() {
		if (!trRef.current?._nodes) return;
		if (trRef.current?._nodes[0]?.attrs.name) {
			const name = trRef.current._nodes[0].attrs.name;
			changePanelAlphanes(name, 'LEAVE');
			trRef.current.getNode()?.draggable(false);
			trRef.current.detach();
			makeFacetSelectable(name);
		}
	}

	function makeFacetTranslative(name: string) {
		if(enableAddPanel || enablePanelMove) {
			return;
		}
		trRef.current?.getNode()?.draggable(false);
		trRef.current?.detach();
		const clickedGroup = groupRefs.current?.find(g => g.attrs.name === name);
		const rs= allRoofSegs.find(r=>r.id===name.split('>')[2]);
		if(!rs || !clickedGroup) return;
		clickedGroup?.draggable(true);
		clickedGroup?.rotation(rs.azimuthDegrees);
		clickedGroup?.getChildren().forEach(ch=>ch.rotation(-rs.azimuthDegrees));
		trRef.current?.setNodes([clickedGroup]);
	}

	const handleGroupClick = useCallback((() => {
		let previousSelectedGroupName: string;
		return function (name: string) {
			console.log('name', name);
		
			if (!previousSelectedGroupName) {
				previousSelectedGroupName = name;
				changePanelAlphanes(name, 'ENTER');
				makeFacetTranslative(name);
				makeFacetSelectable(name);
				return;
			}

			if (previousSelectedGroupName !== name) {
				changePanelAlphanes(previousSelectedGroupName, 'LEAVE');
				changePanelAlphanes(name, 'ENTER');
				makeFacetTranslative(name);
				makeFacetSelectable(name);
				previousSelectedGroupName = name;
				return;
			}
		};
	})(), [editModeEnabled, allRoofSegs]);

	const handleGroupHover = useCallback((() => {
		return function (event: KonvaEventObject<MouseEvent>, name: string) {
			console.log('name', name);
			if (trRef?.current?.isTransforming()) return;
			if(isIndividualPanelOperation) makeFacetSelectable(name);
			
		};
	})(), [editModeEnabled,isIndividualPanelOperation]);

	function changePanelAlphanes(name: string, action: 'ENTER' | 'LEAVE') {
		return;
		const panelObjs = groupRefs.current?.find(g => g.attrs.name === name);
		if (!panelObjs?.children) return;
		const lines = panelObjs?.children as Konva.Line[];
		for (let i = 0; i < lines.length; i++) {
			lines[i].attrs['fill'] = action === 'ENTER' ? FULL_TRANPARENT_BLACK_PANEL : SEMI_TRANPARENT_BLACK_PANEL;
		}
	}

	function handleTouchStart(e: KonvaEventObject<TouchEvent>) {
		if (!editModeEnabled) return;
		clearTimeout(longTouchTimerRef.current);
		longTouchTimerRef.current = setTimeout(() => {
			openEditDeleteOptionPopup({ x: e.evt.touches[0].clientX, y: e.evt.touches[0].clientY });
		}, 2000);
	}

	function handleTouchEndOrCancel() {
		clearTimeout(longTouchTimerRef.current);
	}

	function handleContextMenuEvt(e: KonvaEventObject<MouseEvent>, name: string) {
		if (!canPerformEditMoveActions) return;
		e.evt.preventDefault();
		openEditDeleteOptionPopup({ x: e.evt.clientX, y: e.evt.clientY });
		handleGroupClick(name);
	}

	function openEditDeleteOptionPopup(position: Vector2d) {
		dispatch(setDropdownState({ open: true, position }));
	}

	function handleDragStart(name: string){
		if (!canPerformEditMoveActions) return;
		if (konvaRef?.current) {
			handleGroupClick(name);
			clearTimeout(longTouchTimerRef.current);
			konvaRef.current.container().style.cursor = 'move';
		}
	}

	return (
		<>
			{
				Object.entries(solarPanelsGroupedByRoofIndex).map((ob) => {
					if (deletedRoofIndexes.includes(ob[0])) return null;
					const groupName= `panels>group>${ob[0]}`;
					return (
						<Group
							key={ob[0] + panel.key}
							ref={(el) => {
								if (el && !groupRefs.current?.includes(el)) {
									groupRefs.current?.push(el);
								}
							}}
							name={groupName}
							onContextMenu={(e)=>handleContextMenuEvt(e,groupName)}
							onClick={() => {
								if (!canPerformEditMoveActions || isIndividualPanelOperation) return;
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'move';
								handleGroupClick(groupName);
							}}
							draggable={editModeEnabled && !drawModeEnabled && !moveStage && !isIndividualPanelOperation}
							onTap={() => {
								if (!canPerformEditMoveActions) return;
								handleGroupClick(groupName);
							}}
							onTouchStart={handleTouchStart}
							onTouchEnd={handleTouchEndOrCancel}
							onMouseEnter={(e) => {
								if (!canPerformEditMoveActions) return;
								handleGroupHover(e, groupName);
								// konvaRef?.current?.removeEventListener('mousemove');
							}}
							onMouseLeave={() => {
								if (!canPerformEditMoveActions) return;
							}}
							onDragStart={() => handleDragStart(groupName)}
							onDragEnd={() => {
								if (!canPerformEditMoveActions) return;
								addRoofIndex();
								if (konvaRef?.current)
									konvaRef.current.container().style.cursor = 'default';
							}}
						>
							<PanelsWithHull panelsObj={ob}/>
						</Group>
					);
				})
			}
			{
				(!isIndividualPanelOperation && groupRefs.current) &&
				<Transformer
					ref={trRef}
					anchorSize={20}
					resizeEnabled={false}
					borderEnabled={true}
					rotateAnchorOffset={25}
					borderStroke={'rgba(255, 147, 43, 0.80)'}
					anchorStroke={'rgba(255, 147, 43, 0.80)'}
					anchorFill={'rgba(255, 147, 43, 0.80)'}
					onTranformStart={() => {
						transformTimerRef.current.timeElapsed = performance.now();
						clearTimeout(transformTimerRef.current.timeOut);
					}}
					onTransform={constraintHandleRotation}
					onTransformEnd={() => {
						addRoofIndex();
						transformTimerRef.current.timeElapsed = performance.now() - transformTimerRef.current.timeElapsed;
						queueMicrotask(() => {
							transformTimerRef.current.timeOut = setTimeout(() => {
								transformTimerRef.current.timeElapsed = 0;
							}, 200);
						});
					}}
				/>
			}

			{
				solarPanelsGroupedByRoofIndex &&
				<Facets/>
			}
			{
				<HullCoords/>
			}
		</>
	);
}
export default memo(PanelsGroup);