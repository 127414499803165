const initializeState = ()=>({
	activePanelMode: false,
	enableAddPanel: false,
	newlyAddedPanels: [],
	enablePanelMove: false,
	currentMovablePanels: [],
	deleteIndividualPanelMode: false,
	deletedPanels: []
});

export {
	initializeState,
};

