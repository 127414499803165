
import { getDefaultImageryProvider } from '../../../util';
import { DEFAULT_KONVA_SCALE, DEFAULT_KONVA_STAGE_POSITION } from '../../../constants';

const initializeState = ()=>({
	roofIndexes: [],
	selectedRoofIndex: null,
	deletedRoofIndexes: [],
	newlyCreatedFacets: [],
	editModeEnabled: false,
	threeDModelEnabled: false,
	saveWithPanelColor: true,
	userHasClickedSave: false,
	drawModeEnabled: false,
	isMouseOnMap: false,
	selectedUnfinalizedKonvaFacetId: null,
	deleteUnfinalizedFacet: false,
	zoomLevel: DEFAULT_KONVA_SCALE,
	moveStage: false,
	konvaStagePosition: DEFAULT_KONVA_STAGE_POSITION,
	userHasModifiedRoofSegs: false,
	fullScreenEnabled: false,
	selectedProvider:getDefaultImageryProvider()
});

export {
	initializeState,
};

