import { PANEL_OFF_COLOR } from '../../../constants';
import { Line } from 'react-konva';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store';
import { useKonvaScale } from 'hooks/useKonvaScale';
import { memo, useCallback, useEffect, useRef } from 'react';
import { createPanelToInsert, getMaxPenetrationShape, getSnappedPosition } from '../PanelUtil';
import { useRefDispatch, useRefs } from 'contexts/RefContext';
import { getAllFacetPolygon } from '../util';
import Konva from 'konva';
import { setNewlyAddedPanel } from 'store/slices/PanelSlice';
import { getAbsolutePoints } from 'components/DisplayEnergy/util';
import { ORIENTATION } from 'store/slices/AddPanelModalSlice/types';

const PanelRect = () => {
	const scale = useKonvaScale();
	const { panel, allRoofSegs } = useSelector((state: RootState) => state.roofData.data);
	const { orientation: panelOrientation } = useSelector((state: RootState) => state.addPanelModal.data);
	const { konvaRef } = useRefs();
	const movablePanelRef = useRef<Konva.Line | null>(null);
	const intersectedRef = useRef<Konva.Line | null>(null);
	const refDispatch = useRefDispatch();
	const dispatch = useDispatch();

	useEffect(() => {
		refDispatch({ type: 'ADD_PANEL_REF', payload: { ref: movablePanelRef } });
	}, [movablePanelRef.current]);

	const getPanelPoints = useCallback((orientation: RasterRoofSegment['orientation']) => {
		let orientationNeeded: RasterRoofSegment['orientation'] ;
		if(panelOrientation === ORIENTATION.DEFAULT){
			orientationNeeded = orientation;
		}else if( panelOrientation === ORIENTATION.HORIZONTAL){
			orientationNeeded = 'LANDSCAPE';
		}else{
			orientationNeeded = 'POTRAITT';
		}
		const panelConfig = {
			...panel,
			orientation: orientationNeeded
		};
		return createPanelToInsert(panelConfig, scale);
	},
	[panel, scale]
	);

	const updatePanelPositionAndRotation = useCallback(() => {
		if (!konvaRef?.current || !movablePanelRef?.current) return;
		intersectedRef.current = null;

		const targetFacets = getAllFacetPolygon(konvaRef?.current);
		const intersectedFacet = getMaxPenetrationShape(targetFacets, movablePanelRef?.current as Konva.Line);

		if (intersectedFacet) {
			intersectedRef.current = intersectedFacet;
			const matchingRoofSeg = allRoofSegs.find(seg => seg.id === intersectedFacet.id());
			if (matchingRoofSeg) {
				if(panelOrientation === ORIENTATION.DEFAULT){
					movablePanelRef?.current.rotation(matchingRoofSeg.azimuthDegrees);
					movablePanelRef?.current.points(getPanelPoints(matchingRoofSeg.orientation));
				}
				
				const panels = intersectedFacet.getParent().children?.slice(1) as Konva.Line[];

				if (!movablePanelRef?.current) return;
				const closestEdge = getSnappedPosition(movablePanelRef?.current.position(), movablePanelRef?.current, panels);

				if (closestEdge) {
					// const newp = movablePanel.clone();
					movablePanelRef?.current.position(closestEdge);
				}
			}
		}
	}, [konvaRef, getPanelPoints]);

	function insrtToIntersectedFacet() {
		//
		const intersectedFacet = intersectedRef.current;
		const panelToInsert = movablePanelRef?.current;
		if (!intersectedFacet || !panelToInsert || !konvaRef?.current) return;
		const matchingRoofSeg = allRoofSegs.find(seg => seg.id === intersectedFacet.id());
		if (!matchingRoofSeg) return;
		const exteriorCoords: number[][] = [];
		const coords = getAbsolutePoints(panelToInsert, scale, konvaRef?.current?.position());
		for (let i = 0; i < coords.length; i += 2) {
			exteriorCoords.push([coords[i], coords[i + 1]]);
		}
		panelToInsert.visible(false);

		dispatch(setNewlyAddedPanel({
			addedPanel: {
				exteriorCoords,
				orientation: panelOrientation,
				roofSegmentId: matchingRoofSeg.id,
			}
		}));
	}

	return (
		<Line
			visible={false}
			ref={movablePanelRef}
			name="movable_panel"
			fill={PANEL_OFF_COLOR}
			listening={true}
			strokeWidth={1.4}
			stroke={'black'}
			points={getPanelPoints('LANDSCAPE')}
			draggable={true}
			closed={true}
			onDragMove={() => updatePanelPositionAndRotation()}
			onDragEnd={() => insrtToIntersectedFacet()}

		/>
	);
};

export default memo(PanelRect);
