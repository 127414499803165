import Button from 'components/Basic/Button';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setAddPanel } from 'store/slices/PanelSlice';
import AddPanelModal from './AddPanelModal/index';
import { toggleAddPanelModalState } from 'store/slices/AddPanelModalSlice/addPanelModalSlice';

export default function AddPanelButton() {
	const dispatch = useDispatch<AppDispatch>();
	const { activePanelMode,enableAddPanel, newlyAddedPanels}= useSelector((state: RootState)=>state.panelSlice.data);
	const shouldDisable = activePanelMode && !enableAddPanel;
	function handleClick(){
		dispatch(toggleAddPanelModalState({ value: !enableAddPanel }));
		dispatch(setAddPanel({ shouldEnable: !enableAddPanel }));
	}

	const handleModalConfirm = () => {
		dispatch(setAddPanel({ shouldEnable: true }));
		console.log(newlyAddedPanels);
	};

	const handleModalCancel = () => {
		dispatch(setAddPanel({ shouldEnable: false }));
		console.log(newlyAddedPanels);
	};

	return (
		<>
			<Button style={{
				background: `${enableAddPanel ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
				border: '0px',
				borderRight:'1px solid #C7CACF',
				borderLeft:'0px',
				color: '#28373E',
				fontSize: '15px',
				padding: '0px',
				height: '36px',
				width: '48px',
				borderRadius:'0px'
			}} 
			onClick={handleClick}
			disabled={shouldDisable} title="Add Panel">
				<svg width="15" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
					<path d="M7 17H1V1H13V9.5" stroke={`${enableAddPanel ? '#003CFF' : '#4A525A'}`} strokeWidth="1.6" strokeLinejoin="round"/>
					<path d="M16 15L8 15M12 11L12 19" stroke={`${enableAddPanel ? '#003CFF' : '#4A525A'}`} strokeWidth="1.6" strokeLinejoin="round"/>
				</svg>
			</Button>
			<AddPanelModal 
				onConfirm={handleModalConfirm}
				onCancel={handleModalCancel}/>
		</>
	);

}