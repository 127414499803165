import Button from 'components/Basic/Button';
import { useRefs } from 'contexts/RefContext';
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from 'store';
import { setPanelMove } from 'store/slices/PanelSlice';

export default function MovePanelButton() {
	const dispatch = useDispatch<AppDispatch>();
	const {activePanelMode, enablePanelMove}= useSelector((state: RootState)=>state.panelSlice.data);
	const shouldDisable = activePanelMode && !enablePanelMove;

	function handleClick(){
		dispatch(setPanelMove({ shouldEnablePanelMove: !enablePanelMove }));
	}

	return (
		<Button style={{
			background: `${enablePanelMove ? 'rgb(from #003CFF r g b / 10%)' : '#fff'}`,
			border: '0px',
			borderRight:'1px solid #C7CACF',
			borderLeft:'0px',
			color: '#28373E',
			fontSize: '15px',
			padding: '0px',
			height: '36px',
			width: '40px',
			borderRadius:'0px'
			// height: `${(enableAddPanel || deleteIndividualPanelMode || enablePanelMove) ? '26px' : '34px'}`,
			// width: `${(enableAddPanel || deleteIndividualPanelMode || enablePanelMove) ? '26px' : '34px'}`,
			// boxShadow: `${enableAddPanel ? '1px 4px 6px rgba(100, 100, 100, 0.12)' : '0px 2.25911px 4px rgba(100, 100, 100, 0.12)'}`,
		}} 
		onClick={handleClick}
		disabled={shouldDisable} title="Move Panel">
			<svg width="15" viewBox="0 0 16 19" fill="none" xmlns="http://www.w3.org/2000/svg">
				<path d="M7 17H1V1H13V9.5" stroke="#4A525A" strokeWidth="1.6" strokeLinejoin="round"/>
				<path d="M8 14.5H15M8 14.5L9.07692 13.4231M8 14.5L9.07692 15.5769M15 14.5L13.9231 
				13.4231M15 14.5L13.9231 15.5769M11.5 11V18M11.5 11L10.4231 12.0769M11.5 11L12.5769 
				12.0769M11.5 18L10.4231 16.9231M11.5 18L12.5769 16.9231" stroke="#4A525A" strokeLinecap="round" strokeLinejoin="round"/>
			</svg>

		</Button>
	);

}