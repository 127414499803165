import { Polygon, Response, testPolygonPolygon, Vector } from 'sat';
import Konva from 'konva';
import { PANEL_OFF_COLOR } from '../../constants';
import { RoofData } from 'store/slices/RoofDataSlice/types';

export function getIntersectFacet(targetPos: any, konvaRef: Konva.Stage) {
	const targetPoint = new SAT.Vector(targetPos.x, targetPos.y);
	const facets = konvaRef.find('.movable-facet');
	for (let i = 0; i < facets.length; i++) {
		const facet = facets[i];
		const points = facet.attrs.points;
		const pointPairs = [];
		for (let i = 0; i < points.length; i += 2) {
			pointPairs.push(new SAT.Vector(points[i], points[i + 1]));
		}
		const facetPolygon = new SAT.Polygon(new SAT.Vector(0, 0), pointPairs);
		const collided = SAT.pointInPolygon(targetPoint, facetPolygon);
		if (collided) {
			return facet.id();
		}
	}
	return null;
}

// Helper to convert degrees to radians
function degToRad(deg: number) {
	return (deg * Math.PI) / 180;
}

function getSnappedPosition(
	position: { x: number; y: number },
	currentShape: any,
	siblings: Konva.Node[]
) {
	const snapThreshold = 10; // Define the snapping threshold (adjust this as needed)
	let closestSnapPoint: any = null;
	let closestDistance = Infinity;

	// Convert current shape to an SAT polygon (with the new position)
	const currentPolygon = convertToSATPolygon(currentShape, position);

	// Iterate through sibling shapes
	siblings.forEach((sibling) => {
		if (!(sibling instanceof Konva.Line)) return;

		// Convert sibling shape to SAT polygon
		const siblingPos = sibling.position();
		const siblingPolygon = convertToSATPolygon(sibling, siblingPos);

		// Check for collision using SAT.js
		const response = new Response();
		const collided = testPolygonPolygon(currentPolygon, siblingPolygon, response);

		if (collided) {
			// If collided, resolve the overlap immediately
			const overlapVector = response.overlapV.clone().scale(-1); // Push currentShape away
			const snappedX = position.x + overlapVector.x;
			const snappedY = position.y + overlapVector.y;

			closestSnapPoint = { x: snappedX, y: snappedY };
		} else {
			// No collision, check snapping based on vertices (edges) of the shapes
			const currentVertices = currentPolygon.points;
			const siblingVertices = siblingPolygon.points;

			// Check each vertex of the current shape against all vertices of the sibling shape
			currentVertices.forEach((currentVertex) => {
				siblingVertices.forEach((siblingVertex) => {
					const distance = Math.hypot(
						siblingVertex.x - currentVertex.x,
						siblingVertex.y - currentVertex.y
					);

					// If the distance is within the snapping threshold, update closestSnapPoint
					if (distance < snapThreshold && distance < closestDistance) {
						const snapOffsetX = siblingVertex.x - currentVertex.x;
						const snapOffsetY = siblingVertex.y - currentVertex.y;

						closestSnapPoint = {
							x: position.x + snapOffsetX,
							y: position.y + snapOffsetY,
						};
						closestDistance = distance;
					}
				});
			});
		}
	});

	// Return the snapped position (or null if no snapping is needed)
	return closestSnapPoint ? { x: closestSnapPoint.x, y: closestSnapPoint.y } : null;
}

// Utility to convert a Konva shape (rotated Line) into an SAT.js polygon
function convertToSATPolygon(shape: Konva.Line, position: { x: number; y: number }): Polygon {
	const points = shape.points();
	const vectors = [];
	const rotation = shape.rotation(); // Rotation in degrees
	const rotationRad = degToRad(rotation); // Convert to radians
	const shapeCenter = shape.getClientRect(); // Get bounding box to calculate the center

	// Apply rotation to each point and convert to SAT.js vectors
	for (let i = 0; i < points.length; i += 2) {
		const x = points[i];
		const y = points[i + 1];

		// Calculate the rotated position using the rotation matrix
		const rotatedX =
			Math.cos(rotationRad) * (x - shapeCenter.width / 2) -
			Math.sin(rotationRad) * (y - shapeCenter.height / 2) +
			shapeCenter.width / 2 +
			position.x;
		const rotatedY =
			Math.sin(rotationRad) * (x - shapeCenter.width / 2) +
			Math.cos(rotationRad) * (y - shapeCenter.height / 2) +
			shapeCenter.height / 2 +
			position.y;

		vectors.push(new Vector(rotatedX, rotatedY));
	}

	return new Polygon(new Vector(0, 0), vectors); // The origin can be set as (0, 0)
}

export { getSnappedPosition };

function calculatePenetration(
	movablePolygon: Polygon,
	targetPolygon: Polygon
) {
	const response = new Response();
	const collided = testPolygonPolygon(movablePolygon, targetPolygon, response);

	if (collided) {
		// Overlap magnitude is how much the movable shape has "entered" the target shape
		const penetrationMagnitude = response.overlap;
		// console.log('target-overlap', penetrationMagnitude);

		// Return overlap magnitude (penetration) as a measure of how much the shape has entered
		return { penetrationMagnitude, collided };
	}

	return { penetrationMagnitude: 0, collided: false };
}

function convertShapeToSATPolygon(points: number[]): SAT.Polygon {
	const vertices = [];
	for (let i = 0; i < points.length; i += 2) {
		vertices.push(new Vector(points[i], points[i + 1]));
	}
	// console.log('Converted target vertices:', vertices, points);
	return new Polygon(new Vector(), vertices);
}

export function getMaxPenetrationShape(
	shapes: Konva.Line[],
	targetShape: Konva.Line
): Konva.Line | null {
	let maxPenetration = 0;
	let bestShape: Konva.Line | null = null;

	// Convert target shape's points to SAT polygon
	const points = targetShape.points();
	const pos = targetShape.position();
	// const transfom = targetShape.getAbsoluteTransform();
	const coords= [];
	for (let i = 0; i < points.length; i += 2) {
		const point = { x: points[i]+pos.x, y: points[i + 1]+pos.y };
		// const tp= transfom.point(point);
		coords.push([ point.x, point.y ]);
	}

	// console.log(targetShape.points(), coords.flat(), 'target-shape');
	const targetPolygon = convertShapeToSATPolygon(coords.flat());
	// const targetPolygon = convertShapeToSATPolygon(targetShape, targetShape.position());

	for (const shape of shapes) {
		// Convert each shape's points to SAT polygon
		shape.fill(PANEL_OFF_COLOR);
		// console.log(shape.points(), 'target-shape-facet');
		// const transfom = shape.getAbsoluteTransform();
		// const coords= [];
		// for (let i = 0; i < points.length; i += 2) {
		// 	const point = { x: points[i], y: points[i + 1] };
		// 	const tp= transfom.point(point);
		// 	coords.push([ tp.x, tp.y ]);
		// }

		const shapePolygon = convertShapeToSATPolygon(shape.points());
		// const shapePolygon = convertToSATPolygon(shape, shape.position());

		// Calculate the penetration magnitude between the target and the current shape
		const { penetrationMagnitude } = calculatePenetration(targetPolygon, shapePolygon);
		console.log('target-pen', penetrationMagnitude, shape.id());

		// Track the shape with the maximum penetration magnitude
		if (penetrationMagnitude > maxPenetration) {
			maxPenetration = penetrationMagnitude;
			bestShape = shape;
		}
	}

	if (bestShape) {
		bestShape.fill('yellow');
		bestShape.opacity(.30);
	}

	return bestShape;
}

type PanelConfig = RoofData['panel'] & {
	orientation: Orientation | 'MIXED'
}

export function createPanelToInsert(panel: PanelConfig, scale: Vector2d,){
	const scaledWidth = (panel.width)*scale.x;
	const scaledHeight = (panel.height)*scale.y;

	const isPortrait = panel.orientation=== 'POTRAITT';

	const halfWidth = (isPortrait ? scaledHeight: scaledWidth)/2;
	const halfHeight = (isPortrait ? scaledWidth	: scaledHeight)/2;

	// Define the points relative to the center (x, y)
	const points = [
		-halfWidth, -halfHeight,
		halfWidth, -halfHeight,
		halfWidth, halfHeight,
		-halfWidth, halfHeight,
		-halfWidth, -halfHeight,
	];

	return points;

}

