import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AddPanelModal, ORIENTATION } from './types';


const initialState: { data: AddPanelModal } = {
	data: {
		open: false,
		orientation: ORIENTATION.DEFAULT
	}
};

export const addPanelModalSlice = createSlice({
	name: 'addPanelModelProvider',
	initialState,
	reducers: {
		toggleAddPanelModalState(state, action: PayloadAction<{ value: boolean }>) {
			if (state.data.open === false && action.payload.value === true) {
				state.data.orientation = ORIENTATION.DEFAULT;
			}
			state.data.open = action.payload.value;
		},
		toggleHorizontalState(state, action: PayloadAction<{ value: ORIENTATION }>) {
			state.data.orientation = action.payload.value;
		}
	}
});

export const { toggleAddPanelModalState, toggleHorizontalState } = addPanelModalSlice.actions;

export default addPanelModalSlice.reducer;