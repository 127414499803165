/* eslint-disable @typescript-eslint/no-explicit-any */

import { IMAGERY_PROVIDER } from './constants';

function debounce(func: (...args: any[]) => void, timeout = 50) {
	let debouncedFunc: NodeJS.Timeout | null = null;

	return function (this: ThisParameterType<typeof func>, ...args: any[]) {
		if (!debouncedFunc) {
			debouncedFunc = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		} else {
			clearTimeout(debouncedFunc);
			debouncedFunc = setTimeout(() => {
				func.apply(this, args);
			}, timeout);
		}
	};
}

function mapNumber(mapNum: number, inMin: number, inMax: number, outMin: number, outMax: number) {
	return Math.round((mapNum - inMin) * (outMax - outMin) / (inMax - inMin) + outMin);
}

function createApiUrl(url: string) {
	if (process.env.NODE_ENV === 'production') {
		return `${process.env.REACT_APP_PRODUCTION_API_URL}${url}`;
	}
	return `${process.env.REACT_APP_LOCAL_API_URL}${url}`;
}

function numberInRange(num: number, min: number, max: number): boolean {
	return num >= min && num <= max;
}

function suggestExactAllingmentBasedUponMaxPanelSizes(items: number[], weightLimit: number) {
	const n = items.length;
	console.log('weightLimit', weightLimit);
	const dp = new Array(n + 1).fill(0).map(() => new Array(weightLimit + 1).fill(0));
	for (let i = 1; i <= n; i++) {
		for (let j = 1; j <= weightLimit; j++) {
			if (items[i - 1] <= j) {
				dp[i][j] = Math.max(dp[i - 1][j], items[i - 1] + dp[i - 1][j - items[i - 1]]);
			} else {
				dp[i][j] = dp[i - 1][j];
			}
		}
	}

	const selectedItems = [];
	let i = n;
	let j = weightLimit;
	while (i > 0 && j > 0) {
		if (dp[i][j] !== dp[i - 1][j]) {
			selectedItems.push(i - 1);
			j -= items[i - 1];
		}
		i--;
	}
	// console.log('selectedItems',selectedItems);
	return selectedItems;
}

function suggestAllingmentBasedUponMaxPanelSizes(panels: number[], kwhLimit: number, kwhLimitPLusFityPerc: number) {
	console.log('kwhLimit', kwhLimit, '50', kwhLimitPLusFityPerc);
	let wattsLeft = kwhLimit, i = 0, wattsLeftForFIftyPerc = kwhLimitPLusFityPerc;
	const selectedPanelsIndex = [];
	const unselectedPanelsIndex = [];
	while (wattsLeftForFIftyPerc > 0 && i < panels.length) {
		// console.log('p',panels[i]);
		if (wattsLeft > 0)
			selectedPanelsIndex.push(i);
		else
			unselectedPanelsIndex.push(i);
		wattsLeftForFIftyPerc -= panels[i];
		wattsLeft -= panels[i];
		i++;
	}

	// console.log('selectedItems',selectedPanelsIndex,unselectedPanelsIndex);
	return {
		selectedPanelsIndex,
		unselectedPanelsIndex
	};
}

function getAnnualEnergyFromBillAmount(billPerMonth: number, unitPrice: number) {
	// get it from api instead of assuming
	//cents
	// billPerMonth = billPerMonth
	const electricUnitPrice = unitPrice || 26.5;
	const totalMegaKilloWatt = (billPerMonth * 100) / electricUnitPrice;
	// yearly
	return Math.floor(totalMegaKilloWatt * 12);
}

function getBillAmountFromAnnualEnergy(annualEnergy: number, unitPrice: number) {

	const electricUnitPrice = unitPrice || 26.5;
	annualEnergy /= 12;
	const billPerMonth = (annualEnergy * electricUnitPrice) / 100;
	return Math.floor(billPerMonth);
}

function setDiff<T>(a: Set<T>, b: Set<T>) {
	const diff: T[] = [];
	const sml = a.size < b.size ? a : b;
	const lrg = a.size < b.size ? b : a;

	lrg.forEach(i => {
		!sml.has(i) && diff.push(i);
	});

	return diff;

}

function makePanelKeyFromCoordinate(coord: Coordinate) {
	return `${coord.latitude},${coord.longitude}`;
}

function makeRasterPanelKeyFromTopLeftCorner(coord: number[]) {
	return `${coord[0]},${coord[1]}`;
}

const getToken = (name: string) => {
	const token = localStorage.getItem(`SOLAR_QUOTE_EMBED_${name}`);
	return token;
};

function generateQueryParams(queryParamsObj: { [key: string]: string | number | undefined }): string {
	let queryParamString = '';
	Object.keys(queryParamsObj).forEach((k, i) => {
		const v = queryParamsObj[k];
		if (v) {
			queryParamString += (i === 0) ? `?${k}=${encodeURIComponent(v)}` : `&${k}=${encodeURIComponent(v)}`;
		}
	});
	return queryParamString;
}

function areEqual(arr1: any[], arr2: any[]) {
	if (arr1.length !== arr2.length)
		return false;
	for (let i = 0; i < arr1.length; i++) {
		if (!Object.is(arr1[i], arr2[i]))
			return false;
	}
	return true;
}

// sasti copy of how react memo works
function shallowMemoize<T extends (...args: any[]) => any>(func: T): T {
	let previousArgs: Parameters<T> | undefined;
	let previousResults: ReturnType<T> | undefined;

	return function (this: ThisParameterType<T>, ...args: Parameters<T>) {
		if (!previousArgs) {
			previousResults = func.apply(this, args as any);
			previousArgs = args;
		} else if (!areEqual(previousArgs, args)) {
			previousResults = func.apply(this, args as any);
			previousArgs = args;
		}
		return previousResults;
	} as T;
}

function getDeratedValue(value: number, derate: number ) {
	return (value * (100 - derate)) / 100;
}

function getPercentage(val: number, percent: number) {
	return val * (percent / 100);
}

function removeFalsyValueFromObj(obj: { [key: string]: any }) {
	if (!obj) return obj;
	const newObj: Partial<typeof obj> = {};
	Object.keys(obj).forEach(k => {
		const v = obj[k];
		if (v) newObj[k] = obj[k];
	});
	return newObj;
}

function convertObjValsToNum(obj: { [key: string]: string }) {
	if (!obj) return obj;
	const newObj: { [key: string]: number } = {};
	Object.keys(obj).forEach(k => {
		const v = obj[k];
		if (v) newObj[k] = +obj[k];
	});
	return newObj;
}

function calculateOffsetPercentage(energy: number, billEnergy: number) {
	return Math.round((energy/billEnergy)*100);
}

function getDefaultImageryProvider(){
	return (process.env.REACT_APP_DEFAULT_IMAGERY_PROVIDER || IMAGERY_PROVIDER.SUNROOF ) as ImagerySource;
}

export {
	debounce,
	mapNumber,
	createApiUrl,
	numberInRange,
	suggestExactAllingmentBasedUponMaxPanelSizes,
	suggestAllingmentBasedUponMaxPanelSizes,
	makeRasterPanelKeyFromTopLeftCorner,
	getAnnualEnergyFromBillAmount,
	setDiff,
	makePanelKeyFromCoordinate,
	getToken,
	generateQueryParams,
	shallowMemoize,
	getDeratedValue,
	getPercentage,
	getBillAmountFromAnnualEnergy,
	removeFalsyValueFromObj,
	convertObjValsToNum,
	calculateOffsetPercentage,
	getDefaultImageryProvider,
};
