import styled from 'styled-components';

export const InfoModalPopup = styled.div`
display: block;
width: 100%;
height: 100%;
flex-direction: column;
justify-content: center;	
padding: 14px 14px 8px;
max-height: 280px;
overflow-y: auto;
box-sizing: border-box;
`;

export const ModalcontentInfo = styled.div`
	margin-bottom: 28px;
	max-height: 240px;
	color: #4c4c4c;
	font-size: 1.1em;
	text-align: center;
	font-weight: bold; 
	font-family: 'Poppins', sans-serif;
`;
